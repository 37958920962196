<section class="service-desk">
  <div class="service-desk__left">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="service-desk__left">
            <div class="heading">
              <div class="u-width-70 u-margin-top-large">
                <h2 class="heading--secondary"><span>SERVICE</span> DESK</h2>
              </div>
              <div class="u-margin-bottom-large">
                <h3 class="heading--tertiary">
                  Sie benötigen
                  <span>Hilfe?</span>
                </h3>
              </div>
              <div class="u-margin-bottom-large">
                <h2 class="heading--fourth">
                  Erreichbarkeit: Mo – Fr 8:00 bis 17:00 Uhr
                </h2>
              </div>
            </div>
            <div class="u-margin-bottom-large">
              <ul class="service-desk__list">
                <li class="service-desk__item">
                  <a href="#" class="service-desk__link">
                    <i><i class="fa fa-light fa-arrow-right"></i></i
                    ><span>Service Desk</span></a
                  >
                </li>
                <li class="service-desk__item">
                  <a href="#" class="service-desk__link">
                    <i><i class="fa fa-light fa-arrow-right"></i></i
                    ><span>Service Teams</span></a
                  >
                </li>
                <li class="service-desk__item">
                  <a href="#" class="service-desk__link">
                    <i><i class="fa fa-light fa-arrow-right"></i></i
                    ><span>Serviceportal</span></a
                  >
                </li>
                <li class="service-desk__item">
                  <a href="#" class="service-desk__link">
                    <i><i class="fa fa-light fa-arrow-right"></i></i
                    ><span>Fachsupport</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6"></div>
      </div>
    </div>
  </div>
  <div class="service-desk__right"></div>
</section>
