import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.loadHistory(); // Load history from localStorage on service initialization

    this.router.events
      .pipe(
        filter(
          (event: any): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.history.push(event.urlAfterRedirects);
        this.saveHistory(); // Save updated history to localStorage
      });
  }

  private loadHistory(): void {
    const history = localStorage.getItem('navigationHistory');
    this.history = history ? JSON.parse(history) : [];
  }

  private saveHistory(): void {
    localStorage.setItem('navigationHistory', JSON.stringify(this.history));
  }

  getPreviousUrl(): string {
    return this.history.length > 1 ? this.history[this.history.length - 2] : '';
  }

  getCurrentUrl(): string {
    return this.history[this.history.length - 1] || '';
  }
}
