import { Routes } from '@angular/router';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';

import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UnAuthComponent } from './components/shared/un-auth/un-auth.component';
import { LandingComponent } from './components/portal-components/landing/landing.component';
import { LoadingPageComponent } from './components/shared/loading-page/loading-page.component';
import { HomeComponent } from './components/portal-components/home/home.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'Auth',
  },
  {
    path: 'portal',

    loadChildren: () =>
      import('./components/portal-components/portal.routes').then(
        (rout) => rout.routes
      ),
  },
  // {
  //   path: 'shop',
  //   loadChildren: () =>
  //     import('./components/One-components/One.routes').then(
  //       (rout) => rout.routes
  //     ),
  // },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.routes').then((rout) => rout.routes),
    canActivate: [autoLoginPartialRoutesGuard],
  },
  {
    path: 'Auth',
    component: UnAuthComponent,
  },
  {
    path: 'load',
    component: LoadingPageComponent,
  },
  {
    path: 'landing',
    component: HomeComponent,
    canActivate: [autoLoginPartialRoutesGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
