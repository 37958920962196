import { LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

export const config = {
  triggerAuthorizationResultEvent: true,
  postLoginRoute: window.location.origin,
  forbiddenRoute: '/forbidden',
  unauthorizedRoute: window.location.origin + '/Auth',
  logLevel: LogLevel.Debug,
  historyCleanupOff: false,
  authority: environment.authUrl,
  redirectUrl: window.location.origin + '/load',
  postLogoutRedirectUri: window.location.origin + '/Auth',
  clientId: 'komm-portal',
  scope: 'openid profile email offline_access',
  responseType: 'code',
  silentRenew: true,
  useRefreshToken: true,
  ignoreNonceAfterRefresh: true,
};
