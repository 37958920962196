import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-un-auth',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './un-auth.component.html',
  styleUrl: './un-auth.component.scss'
})
export class UnAuthComponent implements OnInit {
  constructor(private router:Router){}
  ngOnInit(): void {
    setTimeout(()=>{
      this.router.navigate(['/portal']);
    },2000)
  }

}
