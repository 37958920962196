import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-loading-page',
  standalone: true,
  imports: [],
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss',
})
export class LoadingPageComponent {

  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {

  }

  ngOnInit(): void {
    // You can use previousUrl as needed in your component
     if(this.navigationService.getPreviousUrl().includes('profile')){
       localStorage.removeItem('navigationHistory');
      this.router.navigate(['/auth/profile']);
     }
  }
}
