<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="footer__left">
          <div class="footer__logo">
            <img src="../../../../assets/one/img/logo-2.png" alt="" />
          </div>
          <div class="footer__address">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
            doloribus voluptates
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="footer__right">
          <ul class="footer__list">
            <li class="footer__item">
              <a href="#" class="footer__link">Impressum</a>
            </li>
            <li class="footer__item">
              <a href="#" class="footer__link">Datenshutz</a>
            </li>
            <li class="footer__item">
              <a href="#" class="footer__link">Kontakt</a>
            </li>
            <li class="footer__item">
              <a href="#" class="footer__link">Barrierefreiheit</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
