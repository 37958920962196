import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NavBarComponent } from './components/portal-components/nav-bar/nav-bar.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ServiceDeskComponent } from './components/shared/service-desk/service-desk.component';
import { AuthService } from './auth/services/auth.service';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LoadingComponent,
    NavBarComponent,
    CommonModule,
    FooterComponent,
    ServiceDeskComponent,
  ],

  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'komm-portal';

  constructor(
    public router: Router,
    private authService: AuthService,
    public oidcSecurityService: OidcSecurityService
  ) {}
  ngOnInit(): Promise<void> {
    return this.authService
      .checkAuthAndLogin()
      .toPromise()
      .then(() => {        
      });
  }

}
