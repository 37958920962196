import { Component } from '@angular/core';

@Component({
  selector: 'app-service-desk',
  standalone: true,
  imports: [],
  templateUrl: './service-desk.component.html',
  styleUrl: './service-desk.component.scss'
})
export class ServiceDeskComponent {

}
