import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { IMAGE_CONFIG } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { httpInterceptorInterceptor } from './interceptor/http-interceptor.interceptor';
import { loadingInterceptor } from './interceptor/loading.interceptor';
import {
  authInterceptor,
  AuthInterceptor,
  OpenIdConfigLoader,
  provideAuth,
  StsConfigHttpLoader,
} from 'angular-auth-oidc-client';
import { config } from './auth/auth.configu';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from './translation-loader';


export function createTranslateLoader(http: HttpClient, router: Router) {
  return new CustomTranslateLoader(http);
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      })
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, Router],
        },
        defaultLanguage: 'en',
      })
    ),
    provideAnimations(),
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true,
      },
    },
    provideHttpClient(
      withInterceptors([
        loadingInterceptor,
        authInterceptor(),
        httpInterceptorInterceptor,
      ])
    ),
    provideAuth({
      config: config,
    }),
  ],
};
